<template>
  <v-container class="content-page-half">
    <title-header :subtitle="subtitle" />

    <register-partner-first-step
      v-if="step.currentStep === 1"
      @submit="handleFirstStepSubmit"
    />
    <register-partner-second-step
      v-if="step.currentStep === 2"
      @backAction="handleSecondStepBackAction"
      @submit="handleSecondStepSubmit"
    />
    <register-partner-third-step
      v-if="step.currentStep === 3 && userIsAdmin"
      @backAction="handleThirdStepBackAction"
      @submit="handleThirdStepSubmit"
    />
  </v-container>
</template>

<script>
  import TitleHeader from "@/modules/core/views/components/TitleHeader";
  import RegisterPartnerFirstStep from "@/modules/partner/views/components/RegisterPartnerFirstStep";
  import RegisterPartnerSecondStep from "@/modules/partner/views/components/RegisterPartnerSecondStep";
  import RegisterPartnerThirdStep from "@/modules/partner/views/components/RegisterPartnerThirdStep";
  import { preparePhonesToSubmit } from "@/modules/core/helpers/phoneHelpers";

  import { mapGetters, mapActions } from "vuex";
  import store from "@/store";

  export default {
    name: "RegisterPartner",

    beforeRouteLeave: (to, from, next) => {
      store.dispatch("partner/resetCreateState");
      next();
    },

    components: {
      TitleHeader,
      RegisterPartnerFirstStep,
      RegisterPartnerSecondStep,
      RegisterPartnerThirdStep
    },

    mounted() {
      this.setMaxSteps(this.userIsAdmin ? 3 : 2);
    },

    computed: {
      ...mapGetters({
        step: "partner/getCreateStep",
        userIsAdmin: "user/getUserIsAdmin",
        userCrc: "user/getSelectedUserCRC",
        formData: "partner/getFormData",
        contractCrc: "partner/getSelectedCrc",
        pevs: "partner/getPevs"
      }),

      subtitle() {
        return `Passo ${this.step.currentStep} de ${this.step.maxSteps}`;
      }
    },

    methods: {
      ...mapActions("partner", [
        "setMaxSteps",
        "setCurrentStep",
        "setSelectedCrc",
        "createPartner",
        "resetCreateState"
      ]),

      ...mapActions(["toggleSnackbar"]),

      handleFirstStepSubmit() {
        this.setCurrentStep(2);
      },

      handleSecondStepBackAction() {
        this.setCurrentStep(1);
      },

      handleSecondStepSubmit() {
        if (this.userIsAdmin) {
          this.setCurrentStep(3);
        } else {
          this.setSelectedCrc(this.userCrc.id);
          this.savePartner();
        }
      },

      handleThirdStepBackAction() {
        this.setCurrentStep(2);
      },

      handleThirdStepSubmit() {
        this.savePartner();
      },

      async savePartner() {
        try {
          const payload = this.mountPayloadPartner();
          const response = await this.createPartner(payload);

          this.toggleSnackbar({
            text: response?.message ?? "Parceiro criado com sucesso!",
            type: "success"
          });

          this.resetCreateState();
          this.$router.push({ name: "partner" });
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      },

      mountPayloadPartner() {
        return {
          cnpj: this.formData.cnpj,
          companyName: this.formData.companyName,
          agent: {
            ...this.formData.agent,
            phones: this.formData.agent?.phones
              ? preparePhonesToSubmit(this.formData.agent.phones)
              : null
          },
          contract: {
            ...this.formData.contract,
            crc: {
              id: this.contractCrc
            }
          },
          voluntaryDeliveryPoints: this.pevs.map(pev => {
            return {
              name: pev.name,
              businessHours: pev.businessHours,
              address: {
                ...pev.address
              },
              photo: pev.photo
            };
          })
        };
      }
    }
  };
</script>

<style></style>
