<template>
  <v-card class="pt-5">
    <validation-observer>
      <v-form @submit.prevent="submitForm" slot-scope="{ invalid }">
        <div :class="{ 'px-10': $isDesktop || $isTablet, 'px-5': $isMobile }">
          <h3>Informações da empresa</h3>
          <v-row>
            <v-col cols="12">
              <field
                v-model="form.companyName"
                type="genericText"
                label="Nome da empresa"
                name="companyName"
                :isRequired="true"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <field
                v-model="form.cnpj"
                type="cnpj"
                label="CNPJ"
                name="cnpj"
                :isRequired="true"
              />
            </v-col>
          </v-row>

          <h3>Representante</h3>
          <v-row>
            <v-col cols="12">
              <field
                v-model="form.agent.name"
                type="genericText"
                label="Nome"
                name="name"
                :isRequired="true"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <field
                v-model="form.agent.email"
                type="email"
                label="E-mail"
                name="E-mail"
                :isRequired="true"
              />
            </v-col>
          </v-row>
          <phone-field v-model="form.agent.phones" :isRequired="true" />

          <h3>Contrato *</h3>

          <v-row class="contract-container">
            <v-col cols="12">
              <upload-file
                :value="contractFile"
                @input="handleContractFileChange"
                @uploadFile="updateFile"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" lg="6">
              <date-field v-model="form.contract.beginAt" label="Data Início" />
            </v-col>
            <v-col cols="12" lg="6">
              <date-field v-model="form.contract.endAt" label="Data Termino" />
            </v-col>
          </v-row>
        </div>

        <v-divider></v-divider>

        <v-card-actions class="d-flex flex-wrap justify-space-between">
          <v-btn
            @click="$router.push({ name: 'partner' })"
            color="#FFF"
            class="mt-1 cancel-button black-3--text"
            :block="$isMobile"
            >Cancelar</v-btn
          >
          <v-btn
            type="submit"
            :disabled="invalid || !validContract"
            color="secondary-color-1"
            :block="$isMobile"
            class="ma-0 mt-1 next-step-button white--text"
            >Próximo</v-btn
          >
        </v-card-actions>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script>
  import { ValidationObserver } from "vee-validate";
  import Field from "@/modules/core/views/components/Field";
  import DateField from "@/modules/core/views/components/DateField";
  import UploadFile from "@/modules/core/views/components/UploadFile";
  import PhoneField from "@/modules/core/views/components/PhoneField";

  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "RegisterPartnerFirstStep",

    components: {
      ValidationObserver,
      Field,
      DateField,
      UploadFile,
      PhoneField
    },

    mounted() {
      // this.resetContract();
    },

    computed: {
      ...mapGetters("partner", {
        formData: "getFormData"
      }),

      form() {
        return this.formData;
      },

      validContract() {
        return !!this.formData.contract?.file?.id;
      },

      contractFile() {
        return this.formData?.contract?.file?.id
          ? this.formData.contract.file
          : null;
      }
    },

    methods: {
      ...mapActions("partner", ["setFormData", "resetContract"]),

      updateFile(file) {
        this.form.contract.file = { ...file };
      },

      handleContractFileChange(file) {
        if (!file) {
          this.resetContract();
        }
      },

      submitForm() {
        this.setFormData(this.form);
        this.$emit("submit");
      }
    }
  };
</script>

<style></style>
