<template>
  <v-card class="pt-5">
    <div :class="{ 'px-10': $isDesktop || $isTablet, 'px-5': $isMobile }">
      <h3>Adicionar PEV (Ponto de Entrega Voluntária)</h3>

      <v-row>
        <v-col cols="12">
          <btn-dashed
            depressed
            block
            title="Adicionar PEV"
            :btnAction="openAddPevDialog"
          ></btn-dashed>
        </v-col>
      </v-row>

      <pev-list v-if="pevs.length" :height="400" class="my-5">
        <pev-list-item
          v-for="(pev, index) in pevs"
          :key="`pev-item-${index}`"
          :pev="pev"
        >
          <template v-slot:action="{ pev }">
            <v-icon @click="handleRemovePev(pev)">mdi-close</v-icon>
          </template>
        </pev-list-item>
      </pev-list>

      <pev-not-found v-if="!pevs.length" class="mt-7" />

      <add-pev
        :state="addPevDialogState"
        @submit="handleAddPev"
        :key="`add-pev-${addPevDialogKey}`"
      />
    </div>
    <v-divider></v-divider>
    <v-card-actions class="d-flex flex-wrap justify-space-between">
      <v-btn
        color="#FFF"
        class="mt-1 cancel-button black-3--text"
        :block="$isMobile"
        @click="$emit('backAction')"
        >Voltar</v-btn
      >
      <v-btn
        :disabled="!pevs.length"
        color="secondary-color-1"
        :block="$isMobile"
        class="ma-0 mt-1 next-step-button white--text"
        @click="$emit('submit')"
        >{{ btnSubmitLabel }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
  import BtnDashed from "@/modules/core/views/components/BtnDashed";
  import AddPev from "@/modules/partner/views/components/AddPev";
  import PevList from "@/modules/partner/views/components/PevList";
  import PevListItem from "@/modules/partner/views/components/PevListItem";
  import PEVNotFound from "@/modules/partner/views/components/PEVNotFound";

  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "RegisterPartnerSecondStep",

    components: {
      BtnDashed,
      AddPev,
      PevList,
      PevListItem,
      PevNotFound: PEVNotFound
    },

    data: () => ({
      addPevDialogKey: 1,
      addPevDialogState: {
        dialog: false
      }
    }),

    watch: {
      "addPevDialogState.dialog": function(newVal) {
        if (!newVal) {
          this.addPevDialogKey++;
        }
      }
    },

    computed: {
      ...mapGetters("partner", {
        pevs: "getPevs",
        steps: "getCreateStep"
      }),

      btnSubmitLabel() {
        return this.steps.maxSteps === 2 ? "Salvar" : "Próximo";
      }
    },

    methods: {
      ...mapActions("partner", ["addPev", "removePev"]),
      openAddPevDialog() {
        this.addPevDialogState.dialog = true;
      },

      handleAddPev(pev) {
        this.addPev(pev);
        this.addPevDialogState.dialog = false;
      },

      handleRemovePev(pev) {
        this.removePev(pev.localId);
      }
    }
  };
</script>

<style></style>
