<template>
  <v-card class="pt-5">
    <div
      class="mb-5"
      :class="{ 'px-10': $isDesktop || $isTablet, 'px-5': $isMobile }"
    >
      <h3>Vincular a uma CRC</h3>
    </div>
    <select-crc v-model="selectedCrc" :multiple="false" />

    <v-divider></v-divider>
    <v-card-actions class="d-flex flex-wrap justify-space-between">
      <v-btn
        color="#FFF"
        class="mt-1 cancel-button black-3--text"
        :block="$isMobile"
        @click="$emit('backAction')"
        >Voltar</v-btn
      >
      <v-btn
        :disabled="!selectedCrc"
        color="secondary-color-1"
        :block="$isMobile"
        class="ma-0 mt-1 next-step-button white--text"
        @click="submit"
        >Salvar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
  import SelectCRC from "@/modules/crc/views/components/SelectCRC";

  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "RegisterPartnerThirdStep",

    components: {
      SelectCrc: SelectCRC
    },

    computed: {
      ...mapGetters({
        contractCrc: "partner/getSelectedCrc"
      }),
      selectedCrc: {
        get() {
          return this.contractCrc;
        },
        set(newVal) {
          this.setSelectedCrc(newVal);
        }
      }
    },

    methods: {
      ...mapActions("partner", ["setSelectedCrc"]),

      submit() {
        this.setSelectedCrc(this.selectedCrc);
        this.$emit("submit");
      }
    }
  };
</script>

<style></style>
